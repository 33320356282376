<template>
  <div class="project-view" v-if="project">
    <div class="project-hero">
      <img v-img:group :src="project.data.hero_img.url" />
    </div>
    <h1 class="project-title">{{ $prismic.dom.RichText.asText(project.data.title) }}</h1>
    <div class="content-grid">
      <div class="metadata">
        <p class="metadata-text">{{ project.data.year }}</p>
        <p
          v-if="project.data.type.length"
          class="metadata-text"
        >{{ $prismic.dom.RichText.asText(project.data.type) }}</p>
        <p
          v-if="project.data.size.length"
          class="metadata-text"
        >{{ $prismic.dom.RichText.asText(project.data.size) }}</p>
        <p
          v-if="project.data.duration.length"
          class="metadata-text"
        >{{ $prismic.dom.RichText.asText(project.data.duration) }}</p>
        <p
          v-if="project.data.place.length"
          class="metadata-text"
        >{{ $prismic.dom.RichText.asText(project.data.place) }}</p>
        <!-- <p v-if="project.meta.expo" class="metadata-text">{{ $prismic.dom.RichText.asText(project.data.title) }}</p> -->
        <p
          v-if="project.data.collaborators.length"
          class="metadata-text"
        >{{ $prismic.dom.RichText.asText(project.data.collaborators) }}</p>
      </div>
      <div class="content">
        <div
          class="description"
          v-html="$prismic.dom.RichText.asHtml(project.data.description)"
        />
        <template v-for="(media, idx) in project.data.media">
          <img
            v-if="media.image.url"
            :key="idx"
            :src="media.image.url"
            v-img:group
            class="project-img"
            alt="image"
          />
          <!-- TODO: Embeds -->
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Project',
  computed: {
    ...mapGetters({
      project: 'content/getCurrentProject'
    })
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.dispatch('content/setCurrentProject', to.params.slug)
    })
  },
  beforeRouteUpdate (to, from, next) {
    this.$store.dispatch('content/setCurrentProject', to.params.slug)
    next()
  },
  beforeRouteLeave (to, from, next) {
    this.$store.dispatch('content/setCurrentProject', null)
    next()
  }
}
</script>

<style lang="scss">
@import './../scss/colors.scss';

em {
  font-style: italic;
}

.project-img {
  width: 100%;
  padding-top: 50px;
}
.project-hero > img {
  min-width: 100%;
  height: 100%;
  margin: 0 auto;
  object-fit: cover;
  object-position: center;
}
.project-hero {
  overflow: hidden;
  height: 75vh;
}
.content-grid{
  // padding-top: 50px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  // grid-auto-rows: calc(100%);
  grid-gap: 100px 100px;
}
.metadata {
  grid-column: 1 / span 2;
  margin-left: -2px;
}
.content {
  grid-column: 3 / span 5;
}
.project-title {
  font-size: 36px;
  padding: 50px 0px 75px 0px;
  font-weight: 600;
  color: $color-black;
}
.metadata-text {
  font-size: .9rem;
  color: $color-grey;
  font-weight: 300;
  padding-bottom: 1rem;
  line-height: 1.1rem;
  word-break: break-word;
}
.description {
  font-size: 20px;
  line-height: 32px;
  font-weight: 300;
  color: $color-black;
}
</style>
